import { ProductCategory } from "@medusajs/medusa";
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import Link from "next/link";
import React from "react";

export interface BreadcrumbItem {
  name: string;
  link?: string;
  handle?: string;
  className?: string;
  index?: number;
}

interface Props {
  items?: BreadcrumbItem[];
  onClick?: (item: BreadcrumbItem) => void;
  index?: number;
  centered?: boolean;
  arrow?: React.ReactNode;
  setBreadcrumbItems?: React.SetStateAction<BreadcrumbItem>;
  product_category?: ProductCategory;
  product?: PricedProduct;
}

interface BreadcrumbRedirectProps {
  item: BreadcrumbItem;
  currentIndex?: number;
  _index: number;
  itemsCount?: number;
}

const BreadcrumbRedirect = ({ item, currentIndex = 0, _index, itemsCount }: BreadcrumbRedirectProps) => {
  let color = "text-black font-bold";

  if (currentIndex >= _index) {
    color = "text-black";
  }

  if (_index < (itemsCount ?? 1) && (item.link || item.handle)) {
    return (
      <Link href={(item?.link ?? item.handle ?? "#!") as string} className={`inline-flex items-center text-sm font-medium ${color} hover:text-kodi-blue`}>
        {item.name}
      </Link>
    );
  }

  return <span className={`inline-flex items-center font-medium ${color} text-kodi-blue hover:text-kodi-blue-hover cursor-pointer`}>{item.name}</span>;
};

export default function Breadcrumb({ items, onClick, index, centered, arrow, product_category }: Props) {
  if (!arrow) {
    arrow = (
      <svg className="rtl:rotate-180 w-2 h-2 text-black mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" d="m1 9 4-4-4-4" />
      </svg>
    );
  }

  if (product_category) {
    let category = product_category;
    const crumbs = [];
    const home = { name: "Home", handle: "/", id: "cat-home" };

    crumbs.unshift(category);

    // Füge alle Parent-Categories dem Array der Breadcrumbs hinzu, Parents jeweils immer vorne anfügen
    while (category.parent_category) {
      category = category.parent_category;
      crumbs.unshift(category);
    }

    // Füge "Home" als ersten Breadcrumb hinzu
    crumbs.unshift(home);

    return (
      <nav className="flex pb-2.5" aria-label="Breadcrumb">
        <ol className={`w-full inline-flex items-center space-x-1 rtl:space-x-reverse text-sm md:text-base ${centered && "m-auto"}`}>
          {crumbs.map((_category, _index) => {
            const breadcrumb: BreadcrumbItem = { name: _category.name };
            breadcrumb.link = breadcrumb.name === "Home" ? `${_category.handle}` : `/c/${_category.handle}`;
            return (
              <li
                className={`flex items-center font-medium whitespace-nowrap ${breadcrumb.className ?? ""}`}
                key={`breadcrumb-${_category.id}`}
                onClick={() => onClick && onClick(breadcrumb)}
              >
                {_index >= 1 ? arrow : null}
                <BreadcrumbRedirect item={breadcrumb} currentIndex={_index} _index={_index} itemsCount={crumbs.length} />
              </li>
            );
          })}
        </ol>
      </nav>
    );
  }

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className={`inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse ${centered && "m-auto"}`}>
        {items?.map((item: BreadcrumbItem, _index: number) => {
          return (
            <li
              className={`flex items-center font-medium ${item.className ?? ""}`}
              key={`breadcrumbRedirect-${_index}`}
              onClick={() => onClick && onClick(item)}
            >
              {_index >= 1 ? arrow : null}
              <BreadcrumbRedirect item={item} _index={_index} currentIndex={_index} itemsCount={items.length} />
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
